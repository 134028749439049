import React from "react";
import Flex from "../ui/Flex";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import styled from "styled-components";

import leaf from "../../assets/images/leafSquare.jpg";
import lettuceBackground from "../../assets/images/lettuce_background.png"
import oysters from '../../assets/images/gallery/pinkoysters.JPG';
import yen from "../../assets/images/yen_long.JPG";
import linkedin from "../../assets/images/linkedin.webp";

export default function AboutUs() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>About Us</Text>
            <Flex {...styles.AboutUsContainer}>
                <Flex {...styles.LeftAboutUsContainer}>
                    <Image src={leaf} {...styles.LeafImage}/>
                    <Flex {...styles.GoalsTextContainer}>
                    <Text {...styles.TitleText} {...{
                        color: "rgb(212, 214,209)",
                        paddingBottom: ["30px", "35px"],
                        }}>Is this the right fit for you?</Text>
                        <Text {...styles.GoalsBodyText}>
                            My ideal client is deeply invested in supporting local, organic, and regenerative agriculture and values the intentionality behind every meal.  They appreciate high-quality, nutrient-dense foods and seek to deepen their understanding of nourishment—whether by building a strong foundation or refining their existing approach.
                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            L.S.F. is for those ready to take an active role in transforming their relationship with food. My clients are conscious and thoughtful individuals who recognize that creating a truly sustainable culinary lifestyle requires time, effort, and intention. They seek a meaningful, transformative shift—not a quick fix. 

                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            They are open to refining their kitchen organization, elevating ingredient selection, and embracing meal planning strategies and clean living practices that support a nourishing, sustainable, and high-quality way of living.

                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            Regardless of their dietary preferences or skill level, this experience is designed to elevate their culinary confidence and expertise. More than just learning to cook, my clients embrace a hands-on, immersive transformation that aligns with their core values. 

                        </Text>
                        <Text {...styles.GoalsBodyText}>
                            This journey requires active participation—along with commitment, curiosity, and a willingness to experiment and evolve.  Wherever they begin, every client will gain the skills, confidence, and personalized systems needed to seamlessly integrate nourishing meals into their daily life.

                        </Text>


                        <Text {...styles.GoalsBodyText}>
                            If this resonates with you, I invite you to explore the 
                            {" "}<BioLink target="_blank" href="/blueprint">blueprint</BioLink>{" "} to take the next step!

                        </Text>
                        

                    </Flex>
                    
                </Flex>
                <Flex {...styles.RightAboutUsContainer}>
                    <Flex {...styles.VisionTextContainer}>
                        <Text {...styles.VisionParagraph}>
                            Le Savoir Fare is a private culinary consultancy offering a white-glove experience for those committed to an intuitive, elevated, and intentional approach to nourishment—designed to complement and enhance their lifestyle.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            With a background in fine dining, nutrition science, and psychology, I craft a bespoke, immersive, and science-driven experience that empowers my clients to master their kitchen, optimize their health, and cultivate a profound connection to food.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            This service extends far beyond traditional cooking lessons—I provide a hands-on, concierge-level transformation that encompasses kitchen optimization, curated premium ingredient sourcing, tailored culinary planning, and mindful eating strategies. 
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            Every element is designed to make high-quality, nutrient-rich meals a thoughtfully integrated part of daily life. Rooted in clean living and regenerative agriculture, my work ensures that each meal, ingredient, and technique is intentionally selected to foster regenerative health—for both my clients and the ecosystem.
                        </Text>
                        <Text {...styles.VisionParagraph}>
                            As an exclusive, meticulously crafted, high-touch service, I partner with only 12 clients per year, ensuring unparalleled personalization. My clients don't just learn to cook—they refine their culinary mindset, gaining the skills and confidence to reclaim their time through streamlined systems, seamlessly integrating nourishing meals into their daily routine.
                        </Text>
                    </Flex>
                    <Flex {...styles.LettuceContainer}>
                        <Image src={lettuceBackground} {...styles.LettuceBackgroundImage}/>
                        <Image src={oysters} {...styles.OysterImage}/>

                    </Flex>
                </Flex>
            </Flex>
            <Flex {...styles.YenImageContainer}>
                <Image src={yen} {...styles.YenImage}/>
                <Text {...styles.YenniferText}>Yennifer Prada</Text>
            </Flex>
            <Flex {...styles.BioContainer}>
                <Flex {...styles.BioTextContainer}>
                    <Text {...styles.BioText}>
                        Chef Yennifer Prada was born and raised in Venezuela, with Ecuadorian and Colombian heritage. She comes from a family of exceptional cooks who skillfully blended traditional Venezuelan cuisine with Ecuadorian and Colombian influences. At 17, she moved to the United States to pursue a Bachelor of Science in Psychology, and after graduating, she continued her education with an Associate of Science in Culinary Arts in Los Angeles.                    </Text>
                    <Text {...styles.BioText}>
                        While volunteering at the
                        {" "}<BioLink target="_blank" href="https://www.lexusallstarchefclassic.com/">All-Star Chef's Classic</BioLink>{" "}
                        in Los Angeles, Chef Yennifer was offered a position by{" "}<BioLink target="_blank" href="https://www.instagram.com/chefludo/">Chef Ludo Lefebvre</BioLink> at 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petittroisla/">Petit Trois</BioLink>. She was later transferred to 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/troismecla/">Trois Mec</BioLink>, his Michelin-starred restaurant, where she gained invaluable experience. Following this, she was offered the role for opening Junior Sous Chef at 
                        {" "}<BioLink target="_blank" href="https://blacksheeprestaurants.com/">Artemis & Apollo</BioLink> in Hong Kong, where she quickly assumed a leadership role as the sole Sous Chef.
                    </Text>
                    <Text {...styles.BioText}>
                        After returning to the United States, Chef Yennifer moved to San Francisco, where she worked for 
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/dominiquecrenn/">Chef Dominique Crenn</BioLink>, at her Michelin-starred restaurant, 
                        {" "}<BioLink target="_blank" href="https://www.barcrenn.com/">Bar Crenn</BioLink>.  When the COVID-19 pandemic led to the restaurant's temporary closure and heightened food insecurity in the community, she was offered the role of Culinary Manager for the
                        {" "}<BioLink target="_blank" href="https://www.rethinkfood.org/">Rethink Food Project</BioLink> operating from
                        {" "}<BioLink target="_blank" href="https://www.instagram.com/petitcrenn/">Petit Crenn</BioLink>. 
                    </Text>
                    <Text {...styles.BioText}>
                    Chef Yennifer holds a Science of Nutrition certificate from the Stanford Center for Health Education and a Plant-Based Nutrition certificate from the T. Colin Campbell Center for Nutrition Studies—both highly respected programs renowned for their evidence-based approach and focus on the scientific principles of nutrition. She is deeply committed to social and ecological renewal, striving to live a sustainable lifestyle. Through Le Savoir Fare, she aims to inspire awareness and encourage others to embrace these values, fostering a healthier and more sustainable world.
                    </Text>
                    <a href="https://www.linkedin.com/in/yennifer-prada-3500a69a" target="_blank">
                        <Image 
                            src={linkedin}
                            {...styles.LinkedinImage}
                        />
                    </a>

                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const BioLink = React.memo(styled.a`
    text-decoration: underline 1px;
    cursor: pointer;
    color: rgb(254,250,234);
    &:visited {
        color: inherit;
    }
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: ["30px", "90px"]
    },
    AboutUsContainer: {
        flexDirection: ["column-reverse", "row"],
        justifyContent: "center",
        alignItems: "flex-start",
        width: "100vw",
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
    },
    LeftAboutUsContainer: {
        backgroundColor: "rgb(62,82,84)",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "relative",
        height: [null, "1750px"]
    },
    RightAboutUsContainer: {
        backgroundColor: "rgb(206,210,201)",
        flexDirection: "column",
        justifyContent: ["center", "flex-start"],
        alignItems: "center",
        width: "100%",
        zIndex: 1,
        height: [null, "1750px"]
    },
    LeafImage: {
        height: ["300px", "650px"],
        borderRadius: "50%",
        position: "absolute",
        top: "50px",
        right: ["50%", "-150px"],
        marginRight: ["-150px", null],
        zIndex: "0",
    },
    LettuceContainer: {
        maxWidth: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: ["40px", null]
    },
    OysterImage: {
        maxWidth: ["90vw", "80%"],
        height: ["450px", "650px"],
        position: "absolute",
        objectFit: "contain"
    },
    LettuceBackgroundImage: {
        maxWidth: ["100vw", "90vw"],
        height: ["500px", "780px"]    
    },
    GoalsTextContainer: {
        paddingTop: ["400px","700px"], // 650 picture size + 50 padding at top + 90 padding below pic
        paddingBottom: [null, "50px"],
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        width: ["85vw", "500px"],
    },
    GoalsTitleText: {
        fontFamily: fonts.cormorandMedium,
        color: "rgb(212, 214,209)",
        fontSize: "33px",
        width: "400px",
        paddingBottom: "20px"
    },
    GoalsBodyText: {
        fontFamily: fonts.cormorand,
        color: "rgb(212, 214,209)",
        fontSize: ["18px", "20px"],
        paddingBottom: ["35px","45px"],
        lineHeight: 1.3,
        textAlign: "justify",
    },
    zeroFoodprintImage: {
        width: "92px",
    },
    zeroFoodprintContainer: {
        flexDirection: "row",
        justifyContent: ["center", "flex-end"],
        alignItems: "center",
        width: "575px",
        paddingBottom: ["60px", "164px"],
        marginTop: ["40px", "-50px"]
    },
    VisionTextContainer: {
        flexDirection: "column",
        maxWidth: ["85vw", "550px"],
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        paddingTop: ["40px", "115px"]
    },
    VisionTitleText: {
        color: colors.olive,
        fontFamily: fonts.cormorandMedium,
        fontSize: "33px",
        paddingBottom: "65px",
        display: ["none", "block"]
    },
    VisionParagraph: {
        color: colors.olive,
        fontFamily: fonts.cormorand,
        fontSize: ["18px", "20px"],
        paddingBottom: "40px",
        lineHeight: "25px",
        textAlign: "justify",
    },
    YenImageContainer: {
        width: "100%",
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        position: "relative"
    },
    YenImage: {
        width: "100%"
    },
    YenniferText: {
        position: "absolute",
        fontFamily: fonts.cormorandMedium,
        fontSize: ["45px", "68px"],
        color: [colors.beigeBackground, "#DBC5A4"],
        right: ["0%", "18%"],
        lineHeight: [1, null],
        maxWidth: ["180px", "250px"],
        textAlign: ["center", "center"],
        top: ["4%", "30%"]
    },
    BioContainer: {
        paddingLeft: ["0px","40px"],
        paddingRight: ["0px","40px"],
        width: "100%",

    },
    BioTextContainer: {
        width: "100%",
        backgroundColor: "rgb(77,87,65)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "40px",
        paddingLeft: "30px",
        paddingRight: "30px",
        textAlign: 'justify',
    },
    BioText: {
        color: "rgb(254,250,234)",
        fontSize: "18px",
        fontFamily: fonts.cormorandMedium,
        paddingBottom: "25px",
        maxWidth: "960px",
        lineHeight: 1.5,
    },
    LinkedinImage: {
        paddingBottom: "35px",
        cursor: "pointer",
    },
}