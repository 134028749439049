import React, { useState} from "react";
import Flex from "./Flex";
import Text from "./Text";
import lsfLogo from "../../assets/images/LeSavoirFareLOGO.png";
import Image from "./Image";
import menu from "../../assets/images/menu.svg";
import close from "../../assets/images/close-thick.svg"
import { colors, fonts } from "./theme/theme";
import headerLinks from "./headerLinks";
import {useNavigate} from "react-router-dom";
import instagram from "../../assets/images/instagram.svg";



export default function HeaderMobile() {

    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate();

    return (
        <Flex {...styles.Container}>
            <Image src={lsfLogo} {...styles.LsfLogoImage} />
            <Image
                src={menu}
                {...styles.MenuButton}
                onClick={() => {
                    setMenuOpen(!menuOpen)
                }}
            />
            {
                menuOpen && (
                    <Flex {...styles.OpenMenuContainer}>
                        <Image 
                            src={close}
                            {...styles.CloseButton}
                            onClick={() => {setMenuOpen(false)}}
                        />
                        {
                            headerLinks.map(({label, path}) => {
                                return (
                                    <Text
                                        {...styles.LinkText}
                                        onClick={() => {
                                            setMenuOpen(false)
                                            navigate(path)
                                        }}
                                    >
                                        {label}
                                    </Text>
                                )
                            })
                        }
                <Image 
                    src={instagram} 
                    {...styles.instagramIcon}
                    onClick={() => window.open("https://www.instagram.com/lesavoir.fare/")}
                />
                    </Flex>
                )
            }
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        height: "84px",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginBottom: "48px",
        alignItems: "center",
        position: "relative",
        zIndex: 10,
    },
    LsfLogoImage: {
        width: "280px",
        position: "absolute",
        left: "50%",
        marginLeft: "-140px"
    },
    MenuButton: {
        width: "35px",
        paddingRight: "15px",
        cursor: "pointer"
    },
    OpenMenuContainer: {
        zIndex: 10,
        backgroundColor: colors.olive,
        left: 0,
        top: 0,
        height: "100vh",
        width: "100vw",
        position: "fixed",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    LinkText: {
        fontSize: "22px",
        color: colors.beigeBackground,
        fontFamily: fonts.cormorand,
        paddingBottom: "20px"
    },
    CloseButton: {
        position: "absolute",
        top: "30px",
        right: "30px"
    },
    instagramIcon: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
        position: "absolute",
        bottom: "40px",
    },
}